import { api } from '@/shared/api'

export default {
  fetchLogs: async payload => {
    let res = await api.post(`logs`, payload)

    return res
  },
  cleanLogs: async () => {
    return api.delete(`logs`)
  },

  fetchLogSources: async () => {
    return [
      { id: 0, name: 'Backend' },
      { id: 1, name: 'Frontend' },
      { id: 2, name: 'Cron' }
    ]
  },
  fetchLogLevels: async () => {
    return [
      { id: 0, name: 'Error' },
      { id: 1, name: 'Warning' },
      { id: 2, name: 'Debug' },
      { id: 3, name: 'Other' }
    ]
  }
}
