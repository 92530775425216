import crmLogService from './crm-logs.service'
import userManagementService from '@/services/user-management.service'

export default {
  name: 'crm_logs',
  entities: [
    {
      name: 'ID',
      type: 'string',
      readonly: true
    },
    {
      name: 'User Name',
      type: 'dropdown',
      required: true,
      readonly: true,
      optionsService: userManagementService.fetchActiveUsers
    },
    {
      name: 'Source',
      type: 'dropdown',
      required: true,
      optionsService: crmLogService.fetchLogSources
    },
    {
      name: 'Level',
      type: 'dropdown',
      required: true,
      optionsService: crmLogService.fetchLogLevels
    },
    {
      name: 'File',
      type: 'string',
      readonly: true,
      required: true
    },
    {
      name: 'Message',
      type: 'string',
      readonly: true,
      required: true
    },
    {
      name: 'Created At',
      type: 'date',
      readonly: true,
      defaultRange: 'Last 30 days',
      required: true
    }
  ],
  services: {
    fetchRecord: () => {},
    fetchData: crmLogService.fetchLogs,
    saveRecord: () => {},
    deleteRecord: () => {}
  },
  actions: {}
}
